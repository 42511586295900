import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Moment from "react-moment";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import Layout from "components/Layout";

const JobTitle = styled("div")`
  max-width: 550px;
  margin: 0 auto;
  text-align: center;

  h1 {
    margin-top: 6rem;
    color: ${colors.dark};
  }
`;

const JobBody = styled("div")`
  max-width: 640px;
  margin: 0 auto;

  .block-img {
    margin-top: 3.5rem;
    margin-bottom: 0.5rem;

    img {
      width: 100%;
    }
  }
`;

const JobMetas = styled("div")`
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;
  font-size: 0.85rem;
  color: ${colors.grey600};
`;

const JobDate = styled("div")`
  margin: 0;
  text-align: center;
  width: 100%;
`;

const Job = ({ job, meta }) => {
  return (
    <>
      <Helmet
        title={`${job.job_title[0].text}`}
        titleTemplate={`%s | ${meta.title}`}
        meta={[
          {
            name: `description`,
            content: job.job_preview_description[0].text,
          },
          {
            property: `og:title`,
            content: `${job.job_title[0].text} | digitalrain`,
          },
          {
            property: `og:description`,
            content: job.job_preview_description[0].text,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:title`,
            content: meta.title,
          },
          {
            name: `twitter:description`,
            content: job.job_preview_description[0].text,
          },
        ].concat(meta)}
      />
      <Layout>
        <JobTitle>{RichText.render(job.job_title)}</JobTitle>
        <JobMetas>
          <JobDate>
            <Moment format="MMMM D, YYYY">{job.job_date}</Moment>
          </JobDate>
        </JobMetas>
        <JobBody>{RichText.render(job.job_body)}</JobBody>
      </Layout>
    </>
  );
};

export default ({ data }) => {
  const jobContent = data.prismic.allJobs.edges[0].node;
  const meta = data.site.siteMetadata;
  return <Job job={jobContent} meta={meta} />;
};

Job.propTypes = {
  job: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export const query = graphql`
  query JobQuery($uid: String) {
    prismic {
      allJobs(uid: $uid) {
        edges {
          node {
            job_title
            job_date
            job_body
            job_preview_description
            _meta {
              uid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
